<div class="visualizer-actions-wrapper">
  <button
    *ngrxLet="isVisualizerShownAsPiP$ as isShownAsPiP"
    (click)="togglePiP()"
    class="action-btn"
    title="{{ isShownAsPiP ? 'Expand' : 'Shrink' }}"
  >
    <svg-icon [key]="isShownAsPiP ? 'expand' : 'shrink'"></svg-icon>
  </button>
  <button (click)="close()" class="action-btn" title="Close">
    <svg-icon [key]="'times'"></svg-icon>
  </button>
</div>
<div #visualizer id="visualizer" (dblclick)="toggleFullscreen()"></div>
